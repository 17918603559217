<template>
  <div>
    <b-card>
      <b-form>
        <b-row>
          <b-col cols="3">
            <b-form-group
              label-for="trainingSystemId"
            >
              <v-select
                v-model="filter.trainingSystemId"
                :options="trainingSystems"
                :reduce="option => option.value"
                :clearable="false"
                placeholder="Hệ đào tạo"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label-for="courseId"
            >
              <v-select
                v-model="filter.courseId"
                :options="courses"
                :reduce="option => option.value"
                :clearable="false"
                placeholder="Khóa học"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label-for="coursesSemestersSelected"
            >
              <v-select
                v-model="filter.courseSemesterId"
                :options="coursesSemesters"
                :reduce="option => option.value"
                :clearable="false"
                placeholder="Học kỳ:"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label-for="minCGPA"
            >
              <b-form-input
                v-model="filter.minCpa"
                placeholder="Nhập điểm đạt tiêu chí học bổng"
              />

            </b-form-group>
          </b-col>

          <b-col cols="12">
            <div class="text-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="xl"
                class="ml-1"
                variant="primary"
                @click="getDataFromStore"
              >
                <feather-icon icon="SearchIcon" /> Lấy danh sách sinh viên
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="xl"
                class="ml-1"
                variant="success"
                @click="themVaoDSHocBong"
              >
                <feather-icon icon="PlusIcon" /> Lấy vào danh sách học bổng
              </b-button>

            </div>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="mt-2">
        <b-col cols="12">
          <vue-good-table
            mode="remote"
            :columns="columns"
            :rows="dataRows"
            :pagination-options="paginationOptions"
            :total-rows="totalRows"
            :line-numbers="true"
            @on-per-page-change="onPerPageChange"
            @on-page-change="onPageChange"
          >
            <div
              slot="emptystate"
              style="text-align: center; font-weight: bold"
            >
              Không có bản ghi nào !
            </div>
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'subject' && !!props.row.subject">
                Học lại: {{ props.row.subject }}
              </span>
              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Hiển thị </span>
                  <b-form-select
                    v-model="filter.itemsPerPage"
                    :options="itemsPerPageOptions"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> bản ghi/trang</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="totalRows"
                    :per-page="filter.itemsPerPage"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  />
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card>
    <validation-observer
      ref="studentScholarshipSaveFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="modal-1"
        hide-footer
        @show="onShow"
        @hide="onHide"
      >
        <div class="d-block">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label-for="scholarships">
                  <template v-slot:label>
                    Loại học bổng <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Loại học bổng"
                    rules="required"
                  >
                    <v-select
                      v-model="filterAdd.scholarshipId"
                      :options="scholarships"
                      :reduce="option => option.value"
                      placeholder="Chọn loại học bổng"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getElementState(errors)">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-for="courseId">
                  <template v-slot:label>
                    Số lượng SV cần lấy <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Số lượng SV cần lấy"
                    rules="required|integer|min-value:1"
                  >
                    <b-form-input
                      v-model="filterAdd.top"
                      placeholder="Nhập số lương SV cần lấy"
                      :state="getElementState(errors)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  variant="primary"
                  :disabled="invalid"
                  @click="xacNhan"
                >
                  Lưu
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-modal>
    </validation-observer>
  </div>

</template>

<script>
import {
  BButton, BCard, BCol, BFormSelect, BPagination, BRow,
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, minValue, integer } from '@validations'

const user = getUser()
export default {
  name: 'FormFilterStudentScholarship',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
    BFormInvalidFeedback,
    ValidationProvider,
    BButton,
    BCard,
    BCol,
    BFormSelect,
    BPagination,
    BRow,
    VueGoodTable,
    vSelect,
    BForm,
    BFormGroup,
    BFormInput,
  },

  data() {
    return {
      columns: [
        {
          label: 'Tên sinh viên',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Mã sinh viên',
          field: 'code',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lớp',
          field: 'courseName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm Gpa',
          field: 'gpa',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'subject',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      isLoading: false,
      dataRows: [],
      filter: {
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        minGpa: 0,
        currentPage: 1,
        itemsPerPage: 10,
      },
      filterAdd: {
        scholarshipId: null,
        top: null,
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      required,
      minValue,
      integer,
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      filterStudents: 'studentScholarship/filterStudents',
      totalRows: 'studentScholarship/totalRows',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      coursesSemesters: 'dropdown/courseSemesters',
      scholarships: 'dropdown/scholarships',
      systemParameters: 'parameter/dataLists',
    }),
  },
  watch: {
    // listening change data init
    'filter.trainingSystemId': function (newVal) {
      this.onChangeTrainingSystem(newVal)
    },
    'filter.courseId': function (newVal) {
      this.oneChangeCourse(newVal)
    },

  },
  async created() {
    // init data FROM API
    await this.getInitData()
  },
  methods: {
    ...mapMutations({
      setCourses: 'dropdown/SET_COURSES',
      setCourseSemester: 'dropdown/SET_COURSE_SEMESTERS',
    }),
    // handle even
    ...mapActions({
      getByFilter: 'studentScholarship/getByFilter',
      getTrainingSystem: 'dropdown/getTrainingSystems',
      getCourse: 'dropdown/getCourses',
      getCoursesSemesters: 'dropdown/getCourseSemesters',
      getScholarships: 'dropdown/getScholarships',
      themDsHocSinhNhanHocBong: 'studentScholarship/themDsHocSinhNhanHocBong',
      getSystemParameters: 'parameter/getAllData',
    }),
    onShow() {
      this.$refs.studentScholarshipSaveFormRef.validate()
    },
    onHide() {
      this.filterAdd.scholarshipId = this.scholarships[0].value
      this.filterAdd.top = 0
    },
    async getDataFromStore() {
      await this.getByFilter(this.filter)
      this.dataRows = this.filterStudents
    },

    async getInitData() {
      await this.getTrainingSystem({ organizationId: user.orgId })
      if (this.trainingSystems) this.filter.trainingSystemId = this.trainingSystems[0].value
      await this.getScholarships({ organizationId: getUser().orgId, currentPage: 1, itemsPerPage: 100 })
      this.filterAdd.scholarshipId = this.scholarships[0].value

      const systemParams = await this.getSystemParameters()
      this.filter.minCpa = systemParams.find(item => item.code === 'MIN_SCHOLARSHIP_POINT').value
    },

    async onChangeTrainingSystem(id) {
      this.setCourses([])
      await this.getCourse({ organizationId: getUser().orgId, trainingSystemId: id })
      if (this.courses.length > 0) {
        this.filter.courseId = this.courses[0].value
        await this.oneChangeCourse(this.filter.courseId)
      } else {
        this.filter.courseId = null
      }
    },

    async oneChangeCourse(id) {
      this.setCourseSemester([])
      await this.getCoursesSemesters({ organizationId: getUser().orgId, courseId: id })
      if (this.coursesSemesters.length > 0) {
        this.filter.courseSemesterId = this.coursesSemesters[0].value
      } else {
        this.filter.courseSemesterId = null
      }
    },

    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getDataFromStore()
    },

    async onPageChange(param) {
      this.filter.currentPage = param.currentPage
      await this.getDataFromStore()
    },

    async themVaoDSHocBong() {
      this.$bvModal.show('modal-1')
    },

    async xacNhan() {
      const valid = this.$refs.studentScholarshipSaveFormRef.validate()
      if (valid) {
        this.filter.itemsPerPage = +this.filterAdd.top
        await this.getDataFromStore()
        const studentIds = this.dataRows.map(item => item.id)
        if (studentIds.length > 0) {
          const dataSend = {
            studentIds,
            scholarshipId: this.filterAdd.scholarshipId,
            courseSemesterId: this.filter.courseSemesterId,
          }
          const res = await this.themDsHocSinhNhanHocBong(dataSend)
          if (res.isSuccessful) {
            this.showToast('Cập nhật thành công', 'CheckIcon', 'success')
            this.$bvModal.hide('modal-1')
            this.$emit('addSuccess')
          } else {
            this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
          }
        } else {
          this.showToast('Chưa có dữ liệu ', 'XCircleIcon', 'warning')
        }
      }
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped>

</style>
